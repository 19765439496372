<template>
  <v-card v-if="show" class="notify-wrap" :class="{ hide: hideAction }">
    <div @click="moveDetail">
      <v-icon class="bell-gray"></v-icon>
      {{ noticeObj.title }}
    </div>
    <v-btn icon @click="close()">
      <v-icon class="close-gray"></v-icon>
    </v-btn>
  </v-card>
</template>
<script>
import Common from '$$utils/common'
export default {
  name: 'UiNotify', // 'Notify',
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    showYn: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      noticeObj: {},
      show: true,
      hideAction: false,
      closeKey: 'dobu_noti_close'
    }
  },
  watch: {
    item: {
      handler: function (nVal) {
        this.noticeObj = nVal
        this.noticeObj.title = Common.replaceSymbols(nVal.title)
      },
      deep: true
    },
    showYn(nVal) {
      this.show = nVal
    }
  },
  mounted() {
    this.noticeObj = this.item
    this.noticeObj.title = Common.replaceSymbols(this.item.title)
    this.show = this.showYn
  },
  methods: {
    close() {
      this.hideAction = true
      setTimeout(() => {
        this.show = false
      }, 1000)

      let date = new Date()
      date = new Date(date.setDate(date.getDate() + 1))
      const [year, month, day] = [date.getFullYear() + '', date.getMonth() + 1 + '', date.getDate() + '']
      const fulldate = year + (month.length === 1 ? '0' + month : month) + (day.length === 1 ? '0' + day : day) + '000000'
      window.localStorage.setItem(this.closeKey, fulldate)
    },
    moveDetail() {
      this.$router.push({ path: '/CS/CS0101M02', query: { contentsNo: this.noticeObj.contentsNo, rownum: 1 } })
    }
  }
}
</script>
