<template>
  <div class="channel-profile-card" :class="{ active: expanded || disExpanded, 'pb-7': disExpanded }">
    <div class="channel-profile-item">
      <UiUserImg :data="userObj" :img-class="''" :img-type="'finfle'"></UiUserImg>

      <div>
        <div v-if="showChipBtn" class="chip-group">
          <v-chip v-for="(cate, idx) in category" :key="idx" size="small" :color="getColor(cate)">{{ getCategoryNm(cate) }}</v-chip>
        </div>

        <div>
          <p v-dompurify-html="userObj.nickNm" class="name"></p>
          <p v-if="userObj.email" v-dompurify-html="item.email" class="email"></p>
          <v-btn v-if="showLinkBtn" size="x-small" color="gray-darken-4 mt-1" @click="moveFinfle">
            핀플채널
            <v-icon class="arr-right"></v-icon>
          </v-btn>
        </div>
      </div>
      <v-btn v-if="showEditBtn" class="position-absolute" size="x-small" color="gray-darken-4" @click="editProfile">
        <v-icon class="pen" size="small"></v-icon>
        편집
      </v-btn>
    </div>

    <ul class="count-box">
      <li>
        <p class="label">의견</p>
        <div class="value">
          {{ numberFormat(userObj.postCnt, 0) }}
        </div>
      </li>
      <li v-if="showFollower || mine">
        <p class="label">팔로워</p>
        <div v-if="countReadonly" class="value">
          {{ numberFormat(userObj.followerCnt, 0) }}
        </div>
        <button v-else class="value" @click="movePageFollower('/CO/CO0104M01')">
          {{ numberFormat(userObj.followerCnt, 0) }}
          <v-icon class="arr-right"></v-icon>
        </button>
      </li>
      <li v-if="showFollowing || mine">
        <p class="label">팔로잉</p>
        <div v-if="countReadonly" class="value">
          {{ numberFormat(userObj.followingCnt, 0) }}
        </div>
        <button v-else class="value" @click="movePageFollowing('/CO/CO0104M01')">
          {{ numberFormat(userObj.followingCnt, 0) }}
          <v-icon class="arr-right"></v-icon>
        </button>
      </li>
    </ul>

    <v-btn v-if="showLogBtn" class="btn-log" @click="moveActivityLog">
      <v-icon class="log-gray"></v-icon>
      활동로그
    </v-btn>
    <div v-if="userObj.memo" class="docu">
      {{ userObj.memo }}
    </div>
    <div class="sns-wrap" :class="{ 'flex-column': showSnsLink }">
      <template v-for="(snsObj, idx) in snsList" :key="idx">
        <v-btn @click="moveSns(snsObj.snsUrl)">
          <v-icon :class="getSnsClass(snsObj.snsRegisterCd)"></v-icon>
          <p v-if="showSnsLink" class="link">
            {{ snsObj.snsUrl }}
          </p>
        </v-btn>
      </template>
    </div>
    <v-btn v-if="!disExpanded" class="btn-toggle-bar" aria-label="열기/닫기" @click="expanded = !expanded"></v-btn>
  </div>
</template>

<script>
import { UiUserImg } from '$$bizcomponents'
import { isEmpty, isUndefined, find } from 'lodash'
import Common from '$$utils/common'

export default {
  name: 'UiProfileCard', //'ChannelProfileCard',
  components: { UiUserImg },
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    /*
    showFollowing: {
      type: Boolean,
      default: false
    },
    */
    showEditBtn: {
      type: Boolean,
      default: false
    },
    showLinkBtn: {
      type: Boolean,
      default: false
    },
    showLogBtn: {
      type: Boolean,
      default: false
    },
    showSnsLink: {
      type: Boolean,
      default: false
    },
    countReadonly: {
      type: Boolean,
      default: false
    },
    disExpanded: {
      type: Boolean,
      default: false
    },
    showChipBtn: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      mine: false,
      expanded: false,
      userObj: {},
      snsList: [],
      snsCodeList: [],
      userCardCode: [],
      category: []
    }
  },

  computed: {
    userId() {
      const userData = this.$pinia.auth.userData
      if (isEmpty(userData)) return ''
      else return userData.userId
    },
    showFollower() {
      return this.userObj.registerDefaultLevel === 3
    },
    showFollowing() {
      return this.userObj.registerDefaultLevel !== 3
    }
  },

  watch: {
    item: {
      handler: function () {
        this.userObj = this.item
        this.userObj.memo = Common.replaceSymbols(this.item.memo)
        console.log('[this.userObj!!!]', this.userObj.memo)
        this.mine = this.userId === this.userObj.userId

        if (this.userObj.registerDefaultLevel === 3) {
          const findObj = find(this.userCardCode, { value: 'FIN' })
          this.category = [findObj.value]
        } else {
          this.category = []
        }
        if (!isEmpty(this.userObj.snsInfo)) {
          this.snsList = this.userObj.snsInfo.snsList
        } else {
          this.snsList = []
        }
      },
      deep: true
    }
  },

  mounted() {
    this.snsCodeList = this.$pinia.auth.getCommonCode('ST0021')
    this.userCardCode = this.$pinia.auth.getCommonCode('ST0044')
  },

  methods: {
    numberFormat(value, dotNum) {
      if (isUndefined(value)) return ''
      else {
        let dotFNum = isUndefined(dotNum) ? 0 : dotNum
        return Common.numberFormat(value, dotFNum)
      }
    },

    getSnsClass(snsRegisterCd) {
      const snsObj = find(this.snsCodeList, { value: snsRegisterCd })
      if (isEmpty(snsObj)) {
        return ''
      } else {
        return 'sns-' + snsObj.note + '-gray'
      }
    },

    moveSns(url) {
      window.open(url, '_blank')
    },

    moveActivityLog() {
      // 활동로그로 이동
      this.$router.push({ path: '/CO/CO0104M00', state: { userId: this.userObj.userId } })
    },

    editProfile() {
      this.$router.push({ path: '/MB/MB0202M01' })
    },

    getColor(id) {
      const findObj = find(this.userCardCode, { value: id })
      return isEmpty(findObj) ? '' : findObj.note
    },
    getCategoryNm(id) {
      const findObj = find(this.userCardCode, { value: id })
      return isEmpty(findObj) ? '' : findObj.text
    },

    movePageFollower(path) {
      // 팔로워 탭으로 이동
      console.log('팔로워탭 이동 : userId, registerDefaultLevel:', this.userObj.userId, this.userObj.registerDefaultLevel)
      // this.$pinia.com.FOLLOW_NICK_NM_UPDATE(this.userObj.nickNm)
      this.$router.push({
        path: path,
        query: { userId: this.userObj.userId, tab: 0, registerDefaultLevel: this.userObj.registerDefaultLevel },
        state: { nickNm: this.userObj.nickNm }
      }) // tab 0
    },

    movePageFollowing(path) {
      // 팔로잉 탭으로 이동
      console.log('팔로잉탭 이동 : userId, registerDefaultLevel:', this.userObj.userId, this.userObj.registerDefaultLevel)
      // this.$pinia.com.FOLLOW_NICK_NM_UPDATE(this.userObj.nickNm)
      this.$router.push({
        path: path,
        query: { userId: this.userObj.userId, tab: 1, registerDefaultLevel: this.userObj.registerDefaultLevel },
        state: { nickNm: this.userObj.nickNm }
      }) // tab 1
    },

    moveFinfle() {
      this.$router.push({ path: '/CO/CO0101M01/' + this.userObj.userId })
    }
  }
}
</script>
