<template>
  <v-btn-toggle class="select-channel-option" :class="{ small: small }">
    <v-btn v-for="(user, idx) in finfleList" :key="idx" @click="selectedUser(user)">
      <UiUserImg :data="user" :img-type="'finfle'"></UiUserImg>

      <div>
        <p v-if="!small" v-dompurify-html="user.showTopicNm" class="category"></p>
        <p v-if="!small" v-dompurify-html="user.showNickNm" class="name"></p>
        <p v-else v-dompurify-html="user.nickNm" class="name"></p>

        <ul v-if="!small" class="count">
          <li>
            <span class="label">의견</span>
            <span class="value">
              {{ numberFormat(user.postCnt, 0) }}
            </span>
          </li>
          <li>
            <span class="label">팔로워</span>
            <span class="value">
              {{ numberFormat(user.followerCnt, 0) }}
            </span>
          </li>
        </ul>
      </div>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
import { UiUserImg } from '$$bizcomponents'
import { isUndefined } from 'lodash'
import Common from '$$utils/common'
export default {
  name: 'UiSelectChannelList', // 'SelectChannelOption',

  components: {
    UiUserImg
  },

  props: {
    items: {
      type: Array,
      default: () => {
        return []
      }
    },
    size: {
      type: String,
      default: ''
    }
  },

  emits: ['selected-user'],
  data() {
    return {
      small: false,
      finfleList: []
    }
  },

  watch: {
    items: {
      handler: function () {
        this.finfleList = this.items
        // console.log('UiSelectChannelList', this.finfleList)
      },
      deep: true
    }
  },

  mounted() {
    // console.log('UiSelectChannelList', this.items)
    this.finfleList = this.items
    if (this.size == 'small') {
      this.small = true
    }
  },

  methods: {
    numberFormat(value, dotNum) {
      if (isUndefined(value)) return ''
      else {
        let dotFNum = isUndefined(dotNum) ? 0 : dotNum
        return Common.numberFormat(value, dotFNum)
      }
    },
    selectedUser(user) {
      this.$emit('selected-user', user)
    }
  }
}
</script>
