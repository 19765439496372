<template>
  <div class="main-event-wrap" :style="'padding-top:' + (eventList.length - 1) * topHeight + 'px'" :class="{ open: openCard }">
    <v-btn size="x-small" @click="clickOpen">
      <v-icon :class="[openCard ? 'unexpand' : 'expand']"></v-icon>
      {{ openCard ? '접기' : '펼쳐보기' }}
    </v-btn>
    <div
      v-for="(item, index) in eventList"
      :key="index"
      class="event-item"
      :class="[item.class, item.active]"
      :style="'top:' + topHeight * index * -1 + 'px;' + 'z-index:' + (eventList.length - index)"
      @click="clickItem(index)"
      @animationend="removeAction(item)"
    >
      <p class="title">
        {{ item.title }}
      </p>
      <p v-dompurify-html="item.docu" class="docu"></p>
      <div v-if="item.notice" class="notice-box">
        <ul class="notice">
          <li>※ 투자 전 설명 청취 및 상품설명서/약관 필독</li>
          <li>※ 원금손실 발생 가능 및 투자자 귀속</li>
        </ul>
        <v-icon class="logo-hana" aria-label="하나증권"></v-icon>
      </div>
      <v-img :src="item.imgUrl"></v-img>
    </div>
  </div>
</template>
<script>
import { filter } from 'lodash'
export default {
  name: 'UiMainEvent', // 'MainEvent',
  data() {
    return {
      topHeight: 60,
      eventList: [],
      items: [
        {
          class: 'nth-0',
          active: '',
          title: '두부분식 랭킹전!',
          docu: '친구 추천하고 포인트 쌓음 정도를 <br/>굉장히 중요시 여기거덩요~',
          imgUrl: '/assets/events/home_event_01.svg',
          notice: true,
          moveUrl: 'PR0101M01',
          eventEndDt: '20241222'
        },
        {
          class: 'nth-1',
          active: '',
          title: '나야~ 리뷰이벤트',
          docu: '근데 이제 <br/>아이폰16을 곁들인.',
          imgUrl: '/assets/events/home_event_02.svg',
          notice: true,
          moveUrl: 'PR0104M01',
          eventEndDt: '20241222'
        },
        {
          class: 'nth-2',
          active: '',
          title: '출석체크',
          docu: '두부포인트100모 Check!',
          imgUrl: '/assets/events/home_event_03.svg',
          notice: false,
          moveUrl: 'PR0102M01',
          eventEndDt: '99999999'
        }
      ],
      timer: null,
      openCard: false,
      today: '99999999'
    }
  },
  async mounted() {
    const serverTime = await this.getToday()
    this.today = serverTime.substring(0, 8)
    this.eventList = filter(this.items, obj => {
      return obj.eventEndDt >= this.today
    })
  },
  methods: {
    async getToday() {
      const getTodayApi = '/GW/stoplay/login/getnovasysdate'
      let today = ''
      await this.$http.get(getTodayApi, {}).then(result => {
        const res = result.data
        if (res.result.code === 'SUCCESS') {
          today = res.data
        }
      })
      return today
    },
    clickOpen() {
      this.openCard = !this.openCard
    },
    clickItem(index) {
      if (!this.openCard) {
        this.eventList.forEach(item => {
          item.active = ''
        })
        const item = this.eventList[index]
        clearTimeout(this.timer)
        if (index > 0) {
          item.active = 'down'
          this.timer = setTimeout(() => {
            this.eventList.splice(index, 1)
            this.eventList.unshift(item)
            item.active = 'up'
          }, 200)
        } else {
          this.timer = setTimeout(() => {
            item.active = 'click'
            this.$router.push({ name: this.eventList[index].moveUrl })
          }, 100)
        }
      } else {
        this.$router.push({ name: this.eventList[index].moveUrl })
      }
    },
    removeAction(item) {
      if (item.active) {
        item.active = ''
      }
    }
  }
}
</script>
