<template>
  <v-card class="opentalk-list-card">
    <div v-if="category" class="chip-group">
      <v-chip size="small" :color="category.note">{{ category.text }}</v-chip>
    </div>
    <ul class="opentalk-list">
      <li v-for="feed in feedList" :key="feed.contentsNo" @click="moveDetail(feed.contentsNo)">
        <p class="title">{{ replaceAll(feed.contents) }}</p>
        <p class="time">{{ feed.regDtMark }}</p>
      </li>
    </ul>
  </v-card>
</template>
<script>
import { isEmpty, find } from 'lodash'
import Common from '$$utils/common'
export default {
  name: 'UiOpentalkList', //'OpentalkListCard',
  props: {
    item: {
      type: Array,
      default: () => {
        return []
      },
      required: true
    }
  },
  data() {
    return {
      category: {
        value: '',
        text: '',
        note: '',
        detail: ''
      },
      categoryList: [],
      feedList: []
    }
  },
  watch: {
    item: {
      handler: function (nVal) {
        this.feedList = nVal
        console.log('this.feedList', this.feedList)
        if (!isEmpty(this.feedList)) {
          const findObj = find(this.categoryList, { value: this.feedList[0].postSubjectCd })
          if (!isEmpty(findObj)) {
            this.category = findObj
          }
        }
      },
      deep: true
    }
  },
  mounted() {
    this.categoryList = this.$pinia.auth.getCommonCode('ST0031') // 오픈톡 코드
  },
  methods: {
    replaceAll(val) {
      return Common.replaceSymbols(val)
    },
    moveDetail(id) {
      this.$router.push({ name: 'CO0102M02', query: { contentsNo: id } })
    }
  }
}
</script>
